import axios from "axios";
import _this from "@/main";
import { showFullScreenLoading, tryHideFullScreenLoading } from "@/components/Loading/fullScreen";
import { debounce } from "@/utils";
import { checkStatus } from "./helper/checkStatus";

// 设置baseURL-暂时不做处理(有多个地址)
// axios.defaults.baseURL = "https://mp.ynmhkq.com/";
axios.defaults.timeout = 10000;
// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    showFullScreenLoading();
    // 在发送请求之前做,添加token
    const token = JSON.parse(localStorage.getItem("token")) || "";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function ({ status, data }) {
    if (status == 200) {
      tryHideFullScreenLoading();
      return data;
    }
  },
  // 对响应错误做点什么
  function (error) {
    let { message, response } = error;
    tryHideFullScreenLoading();
    if (message == "Network Error") {
      this.$message.error("后端接口连接异常");
    } else if (message.includes("timeout")) {
      this.$message.error("系统接口请求超时");
    }
    console.log(response);
    // 根据服务器响应的错误状态码，做不同的处理
    if (response)
      // 防止出现多次弹窗
      debounce(() => {
        checkStatus(response.status);
      }, 1000);
    return Promise.reject(error);
  }
);
