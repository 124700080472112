import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui";
import { staticRouter } from "./modules/staticRouter";
import { addDynamicRouter } from "./modules/dynamicRouter";
Vue.use(VueRouter);
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
// 在index.js 里面写入下面的代码即可解决
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...staticRouter],
});
// 提供一个重置路由的方法
export const resetRouter = () => {
  router.matcher = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [...staticRouter],
  });
};
//定义变量判断是否已经动态添加过，如果刷新后load永远为 0
let load = 0;
router.beforeEach(async function (to, from, next) {
  const token = JSON.parse(localStorage.getItem("token")) || "";
  if (token) {
    // 非登录、 没有进行添加（或者刷新了）
    if (load === 0 && to.name !== "login") {
      load++;
      // 重新添加路由
      addDynamicRouter();
      next({ path: to.fullPath });
    }
    // 如果有token，说明已经登录，直接放行
    if (to.path === "/login") {
      next({ path: "/spread" });
    } else {
      next();
    }
  } else {
    // 如果没有token，说明没有登录
    if (to.path === "/login") {
      next();
    } else {
      Message.error("请先登录！");
      next({ path: "/login" });
    }
  }
  // 其他情况直接放行
  next();
});
export default router;
