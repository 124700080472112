// 本地路由信息表
export const routes = [
  {
    path: "/spread/display",
    name: "display",
    component: () => import("../../views/spread/display/index.vue"),
    meta: {
      title: "展示推广",
    },
  },
  {
    path: "/spread/client",
    name: "client",
    component: () => import("../../views/spread/client/index.vue"),
    meta: {
      title: "外部客户",
    },
  },
  {
    path: "/spread/dosingReport",
    name: "dosingReport",
    component: () => import("../../views/spread/dosingReport/index.vue"),
    meta: {
      title: "加粉报告",
    },
  },
  {
    path: "/spread/clue",
    name: "clue",
    component: () => import("../../views/spread/clue/index.vue"),
    meta: {
      title: "转化线索",
    },
  },
  {
    path: "/spread/page",
    component: () => import("@/views/spread/page/manage/index.vue"),
    meta: {
      title: "模块建站",
    },
  },
  {
    path: "/spread/page/create/:id?",
    component: () => import("@/views/spread/page/create/index.vue"),
    meta: {
      title: "模块建站-创建",
    },
  },
  {
    path: "/spread/site",
    component: () => import("@/views/spread/site/manage/index.vue"),
    meta: {
      title: "站点管理",
    },
  },
  {
    path: "/spread/site/create/:id?",
    component: () => import("@/views/spread/site/create/index.vue"),
    meta: {
      title: "站点管理-创建",
    },
  },
  {
    path: "/spread/rule",
    component: () => import("@/views/spread/rule/manage/index.vue"),
    meta: {
      title: "分配规则",
    },
  },
  {
    path: "/spread/rule/create/:id?",
    component: () => import("@/views/spread/rule/create/index.vue"),
    meta: {
      title: "分配规则-创建",
    },
  },
  {
    path: "/spread/config",
    component: () => import("@/views/spread/config/index.vue"),
    meta: {
      title: "转化追踪",
    },
  },
  {
    path: "/manage/module",
    component: () => import("@/views/manage/module/index.vue"),
    meta: {
      title: "模块管理",
    },
  },
  {
    path: "/manage/company",
    component: () => import("@/views/manage/company/index.vue"),
    meta: {
      title: "企业管理",
    },
  },
  {
    path: "/manage/role",
    component: () => import("@/views/manage/role/index.vue"),
    meta: {
      title: "角色管理",
    },
  },
  {
    path: "/manage/person",
    component: () => import("@/views/manage/person/index.vue"),
    meta: {
      title: "人员管理",
    },
  },
];
