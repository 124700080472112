import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// reset style sheet
import "@/styles/reset.scss";
// CSS common style sheet
import "@/styles/common.scss";
import "../auto-import-elementUI";
import "./assets/css/global.scss";
import "@/utils/index.js";
import "@/api/index";
import "animate.css";
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

Vue.config.productionTip = false;

const vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vue;
